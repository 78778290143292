import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";

import jsonDataInit from "../../data/about/tender-notice.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const TenderNotice = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a7876a14e1d5002d37c7cc"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Tender Notice" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Tender Notice"
            />
            <Container style={{ margin: "20px auto 30px" }}>
                <h3 style={{ color: "#413565" }}>Tender Notices</h3>
                <ul>
                    {jsonData.map((li, j) => {
                        return (
                            <li
                                key={j}
                                style={{
                                    fontSize: "20px",
                                    paddingLeft: "15px",
                                }}
                            >
                                <a
                                    style={{ color: "#000" }}
                                    href={li.link}
                                    target="blank"
                                    download
                                >
                                    {li.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </Container>
        </Layout>
    );
};
TenderNotice.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query TenderNoticeQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default TenderNotice;
